<template>
  <div class="CompanyIntroduction">
    <!-- logo -->
        <div class="banner container-fuild text-center">关于我们</div>
        <div class="container">
          <div class="col-xs-12 col-sm-12 col-md-8 text-center" v-html="articledata"></div>
        </div>
      <!-- 这里是页脚 -->
      <Footer />
  </div>
</template>

<script>
import {get, post} from "../network/request";
import Header from '../components/hearder.vue'
import Footer from '../components/footer.vue'
import Menu from '../components/menu.vue'
import Main from '../components/main.vue'
export default {
    data() {
        return {
          src: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
          tabPosition: 'left',
          activeindex: '1',
          cc:1,
          articledata:''
        }
    },
    components:{
      Header,
      Footer,
      Menu,
      Main,
    },
    created(){
      console.log('created')
      // this.getarticle()
      this.id = this.$route.params.id
    },
    mounted(){
      this.getlist(this.id)
    },
    methods:{
      changecc1(){
        this.cc = 1;
      },
      changecc2(){
        this.cc = 2;
      },
      getlist(id){
            get('/ess/ess-article/getAllArticle/' + id + '/1/5',{
            
            }).then(res=>{
            console.log(res)
            if(res.data.code == 200)
            {
                get('/ess/ess-article/get/' + res.data.data.data[0].id,{

                }).then(res=>{
                    console.log(res);
                    this.articledata = res.data.data.articleContext
                })
            }
            }).catch(err=>{
            console.log(err)
            })
        },
      getarticle(){
        post('/inform',{
          "module_id":1
        }).then(res=>{
          console.log(res)
          if(res.data.code == 200)
          {
            this.articledata = res.data.data[0].content;
            console.log( res.data.data.content[0])
          }
        }).catch(err=>{
          console.log(err)
        })
      }
    }
}
</script>

<style scoped>
.banner{
    color: #fff;
    font-size: 30px;
    height: 150px;
    line-height: 150px;
    background-image: url('../assets/img/banner1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    background-position: center center;
}
.container{
  /* text-align: center; */
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 30%;
}
</style>

<template>
<el-row class="tac">
    <el-menu
      :uniqueOpened="true"
      :default-active="1"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#F2F6FC"
      text-color="#545c64"
      active-text-color="#0066CC">
      <el-menu-item v-for="(item,index) in modulelist" :key="index" :index="index+1">
        <template #title>
          <i class="el-icon-location"></i>
          <span>{{ item.module_name }}</span>
        </template>
      </el-menu-item>
    </el-menu>
</el-row>
</template>

<script>
import {post} from '../network/request'
  export default {
    data(){
      return {
        modulelist:[]
      }
    },
    //props: ['tableData'],
    created(){
      console.log(23)
      this.getData()
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      getData() {
        post('/semodule',{

        }).then(res=>{
          console.log(res)
          if(res.data.code == 200)
          {
            this.modulelist = res.data.data;
          }
          }).catch(err=>{
            console.log(err)
          })
      }
    }
  }
</script>

<style lang="less" scoped>
    .tac{
        width: 200px;
        height: 100%;
        padding-top: 20px;
    }

</style>
